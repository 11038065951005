<template>
  <div class="body-container">
    <el-container>
      <el-header height="80px">
        <div class="logo-ctx">
          <img :src="logoSrc" alt="" class="logo-image">
          <span class="title">松果Cam</span>
        </div>

        <div class="tab-ctx">
          <a v-for="tab in tabs" :key="tab.label" class="a-link" :class="{ 'is-active': activeTab === tab.label }" :name="tab.label">{{ tab.name }}</a>
        </div>
      </el-header>
      <el-main>
        <div class="dashboard-ctx">
          <div style="width: 50%">
            <p class="title">松果智能云摄像机</p>
            <p>4G联网，看家护院更放心</p>
          </div>
          <div>
            <img :src="img1" alt="" class="img-ctx">
          </div>
        </div>

        <div class="func-ctx">
          <div class="left-ctx">
            <img :src="img2" alt="">
            <img :src="img4" alt="">
            <img :src="img6" alt="">
          </div>
          <div class="right-ctx">
            <img :src="img3" alt="">
            <img :src="img5" alt="">
          </div>
        </div>

        <div class="down-ctx">
          <p class="title">松果Cam App下载</p>
          <div class="page-ctx">
            <div class="img-ctx">
              <el-button color="#ff9951" type="primary" size="large" @click="onDownloadAndroid">安卓手机端App
              </el-button>
              <vue-qr :logoSrc="imageUrl" :text="androidUrl" :size="200"></vue-qr>
            </div>

            <div class="img-ctx">
              <el-button color="#ff9951" type="primary" size="large" @click="onDownloadAndroid">微信端下载App
              </el-button>
              <vue-qr :logoSrc="imageUrl" :text="wechatUrl" :size="200"></vue-qr>
            </div>

            <div class="img-ctx">
              <el-button color="#ff9951" type="primary" size="large" @click="onDownloadIos">苹果手机端App</el-button>
              <vue-qr :logoSrc="imageUrl" :text="iosUrl" :size="200"></vue-qr>
            </div>
            <div class="img-ctx">
              <img :src="img7" alt="">
            </div>
          </div>
        </div>

        <div class="help-ctx">
          <p class="title">希望您能选择<b class="prod-title">松果Cam</b>，如果您有兴趣，欢迎联系我们</p>
          <div>
            <p>电话：4007361098</p>
            <p>邮箱：service@bw30.com</p>
            <p>地址：北京市海淀区国兴大厦5层</p>
          </div>
        </div>
      </el-main>
      <!-- <el-footer height="80px">备案号等相关信息（需是北纬蜂巢物联科技有限公司）</el-footer> -->
      <el-footer height="80px">
        <span class="footer-info">
          <span>© 2020 All Rights Reserved. 北京北纬蜂巢物联科技有限公司 版权所有</span>
          <span>京公网安备11010802041777</span>
          <el-link class="a-link" target="_blank" href="https://beian.miit.gov.cn/" type="primary" :underline="false">京ICP备2022007674号-1</el-link>
          <span> 丨 咨询邮箱：service@bw30.com</span>
        </span>
        <router-link to="/privacy" target="_blank" class="privacy-link">
          <el-link type="primary" :underline="false" style="color: #fff">《松果Cam 隐私政策》</el-link>
        </router-link>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { ref } from 'vue'
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { useRouter } from 'vue-router'

export default {
  name: '',

  components: { vueQr },

  setup () {
    const router = useRouter()
    const ua = navigator.userAgent.toLowerCase()
    var isAndroid = ua.indexOf('android') > -1 || ua.indexOf('linux') > -1;

    const logoSrc = require('../assets/logo.png')
    const img1 = require('../assets/u1.png')
    const img2 = require('../assets/u2.png')
    const img3 = require('../assets/u3.png')
    const img4 = require('../assets/u4.png')
    const img5 = require('../assets/u5.png')
    const img6 = require('../assets/u6.png')
    const qrcode = require('../assets/qrcode.png')
    const img7 = require('../assets/u48.png')

    const imageUrl = require('../assets/logo.png')


    const tabs = [
      { label: 'dashboard', name: '首页' },
      { label: 'func', name: '产品功能' },
      { label: 'download', name: '下载软件' },
      { label: 'question', name: '常见问题' },
      { label: 'contact', name: '联系我们' }
    ]

    const activeTab = ref('dashboard')

    const iosUrl = 'https://apps.apple.com/cn/app/%E6%9D%BE%E6%9E%9Ccam/id1609007241'

    const cannotDown = ua.match(/MicroMessenger/i) == 'micromessenger' && isAndroid // 不能直接下载 先跳转浏览器

    const wechatUrl = window.location.origin + '/#/scanCode'

    const androidUrl = 'https://fcwl-datacenter.oss-cn-beijing.aliyuncs.com/pinecone_cam/app-release_sign.apk?Expires=1978328800&OSSAccessKeyId=LTAI5t7aaJJ9mcMc3p3vPTUv&Signature=HmQza%2FYcYpeKczB9XTIjx7af2E8%3D'

    const onDownloadIos = () => {
      window.open(iosUrl)
    }

    const onDownloadAndroid = () => {
      if (cannotDown) {
        console.log("不可以下载")
        router.replace({ path: '/scanCode' })
      } else {
        console.log("下载")
        const link = document.createElement('a')
        // 设置下载文件的url地址
        link.setAttribute('href', `https://fcwl-datacenter.oss-cn-beijing.aliyuncs.com/pinecone_cam/app-release_sign.apk?Expires=1978328800&OSSAccessKeyId=LTAI5t7aaJJ9mcMc3p3vPTUv&Signature=HmQza%2FYcYpeKczB9XTIjx7af2E8%3D`)
        // 用于设置下载文件的文件名
        link.setAttribute('download', '松果Cam')
        link.click()
      }
    }

    return {
      logoSrc,
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
      qrcode,
      img7,
      imageUrl,
      iosUrl,
      androidUrl,
      wechatUrl,

      tabs,
      activeTab,

      onDownloadIos,
      onDownloadAndroid
    }
  }
}
</script>

<style lang="scss" scoped>
.logo-ctx {
  padding-left: 60px;
  display: flex;
  align-items: center;

  .title {
    font-size: 24px;
    color: #ff9951;
  }

  .logo-image {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
}

.tab-ctx {
  display: flex;
  align-items: center;
  margin-right: 30%;

  .a-link {
    padding: 0 20px;
    font-size: 18px;
  }

  .is-active {
    color: #ff9951;
  }
}

.dashboard-ctx {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #f5f5f5;

  .img-ctx {
    width: 60%;
  }

  .title {
    font-size: 28px;
  }
}

.func-ctx {
  display: flex;
  justify-content: space-between;
  padding: 0 10%;

  img {
    width: 100%;
  }

  img + img {
    margin-top: 15%;
  }

  .left-ctx {
    width: 50%;
  }

  .right-ctx {
    width: 50%;
    padding-top: 8%;
    padding-left: 10%;
  }
}

.down-ctx {
  padding: 6% 15%;
  background: #b8e3f5;

  .title {
    font-size: 28px;
    text-align: left;
  }

  .page-ctx {
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .img-ctx {
      display: flex;
      align-items: center;
      flex-direction: column;

      img {
        margin-top: 30px;
      }
    }
  }
}

.help-ctx {
  padding: 6% 15%;
  text-align: left;

  p {
    line-height: 32px;
  }

  .title {
    font-size: 28px;
    margin-bottom: 6%;

    .prod-title {
      color: #ff9951;
    }
  }
}
</style>

<style lang="scss">
.el-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.el-main {
  padding: 0;
}

.el-footer {
  background: #ff9951;
  color: #fff;
  line-height: 80px;

  .footer-info {
    font-size: 12px;

    span {
      margin: 0 5px;
    }

    .a-link {
      color: #fff;
      font-size: 12px;
    }
  }

  .privacy-link {
    float: right;
    text-decoration: none;
  }
}
</style>
